.cb-map-base-hidden {
  #base {
    display: none;
  }
}

.cb-map-objects-hidden {
  #objects {
    display: none;
  }
}

.cb-map-areas-hidden {
  #areas {
    display: none;
  }
  #areaLabels {
    display: none;
  }
}

.cb-map-labels-hidden {
  #text {
    display: none;
  }
}

.cb-map-layer {
  transform-origin: center;

  text {
    transform: rotate(var(--text-rotation));
    transform-box: fill-box;
    transform-origin: center;
  }

  #base {
    stroke: var(--outline-color, #3b3235);
    stroke-width: 1;
    fill-rule: evenodd;
    fill: var(--base-color, #0e0a0b);
  }

  #areas {
    fill: var(--area-color, #3b3235);
    fill-opacity: 0.15;
    stroke-opacity: 0.35;
    stroke-width: 2;
    stroke: var(--area-color);
  }

  #objects {
    stroke: var(--objects-color, #7d7376);
    fill: var(--objects-color, #7d7376);
    stroke-width: 1.5;
  }

  #objects .line {
    fill: transparent;
    stroke-width: 1.5;
  }

  #areaLabels {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0em;

    #attacker {
      fill: var(--attackers-text-color, #08a3da);
    }

    #defender {
      fill: var(--defender-text-color, #ff003a);
    }

    #A,
    #B,
    #C {
      fill: var(--area-color, #000);
    }
  }

  #text {
    font-family: Plus Jakarta Sans;
    font-size: 9px;
    font-weight: 800;
    letter-spacing: 0em;
    fill: var(--normal-text-color);
  }
}

//TODO: put all the stuff with colors in here
